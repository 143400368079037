<template>
  <div class="privacy-policy">
    <Header />
    <iframe
      id="iframe"
      class="iframe"
      src="/static/privacy_policy/privacy_policy.html"
      width="100%"
      :height="iframeHeight"
      frameborder="0"
      :onload="iframeLoaded"
    ></iframe>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "PrivacyPolicy",
  components: { Header, Footer },
  data() {
    return {
      iframeHeight: "500px",
    };
  },
  mounted() {},
  methods: {
    iframeLoaded() {
      const iframe = document.getElementById("iframe");
      this.iframeHeight =
        iframe.contentWindow.document.body.scrollHeight + 50 + "px";
    },
  },
};
</script>

<style scoped>
.iframe {
  padding-top: 50px;
  background-color: #ffffff;
}
</style>
